import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/SEO/seo"
import { GatsbyImage } from "gatsby-plugin-image";
import { Accordion, AccordionWithLink } from "../components/Accordion"

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 60%;
  justify-content: center;
  grid-row-gap: 20px;
  color: #33475b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  margin-top: 50px;
  @media (max-width: 1024px) {
    margin-top: 30px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 95%;
    margin-top: 0;
  }
`
const PartnerH1 = styled.h1`
  font-size: 62px;
  text-align: center;
  color: #33475b;
  font-weight: 400;
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
  @media (max-width: 800px) {
    font-size: 42px;
  }
`
const PartnerH2 = styled.h2`
  font-size: 55px;
  text-align: center;
  color: #33475b;
  font-weight: 400;
  margin-bottom: 0;
  @media (max-width: 800px) {
    font-size: 38px;
  }
`
const TitleSubH3 = styled.h3`
  font-size: 16px;
  text-align: center;
  color: #33475b;
  font-weight: 400;
  padding: 0 11%;
  @media (max-width: 800px) {
    padding: 0;
  }
`
const TitleSubText = styled.p`
  font-size: 16px;
  text-align: center;
  color: #33475b;
`
const ApplyAffiliateGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
`
const ApplyAffiliateButton = styled.div`
  padding: 20px 30px;
  color: white;
  background-color: #33cdd1;
  font-size: 20px;
  cursor: pointer;
  @media (max-width: 620px) {
    text-align: center;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`
const StyledA = styled.a`
  text-decoration: none;
`
const CardContainerPartner = styled.div`
  display: grid;
  grid-template-columns: 21% 21% 21%;
  justify-content: center;
  grid-column-gap: 2.5%;
  grid-row-gap: 80px;
  margin: 80px 0 60px 0;
  @media (max-width: 1200px) {
    grid-template-columns: 24% 24% 24%;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 26% 26% 26%;
  }
  @media (max-width: 800px) {
    grid-template-columns: 34% 34%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 70%;
  }
  @media (max-width: 430px) {
    grid-template-columns: 80%;
  }
`
const StyledCardPartner = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  /* align-content: start; */
  padding: 0 15px;
  font-family: 'Montserrat', sans-serif;
  grid-row-gap: 25px;
  height: 100%;
  background-color: white;
  color: #33475b;
  /* grid-template-rows: ; */
`
const CardPartnerTitle = styled.h4`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  @media (max-width: 1450px) {
    font-size: 26px;
  }
`
const CardPartnerText = styled.p`
  font-size: 19px;
  margin: 0;
  padding-right: 10px;
  line-height: 1.4;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const CardPartnerButtonGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  align-content: end;
  padding-top: 10px;
`
const CardPartnerButton = styled.div`
  border: 3px solid #33cdd1;
  background-color: white;
  padding: 20px 30px 20px 30px;
  font-size: 16px;
  color: #33cdd1;
  /* margin-top: auto; */
  &:hover {
    background-color: #33cdd1;
    color: white;
  }
`
const ContentLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 38% 26%;
  grid-column-gap: 2%;
  margin: 70px 0 40px 0;
  justify-content: center;
  @media (max-width: 1200px) {
    grid-template-columns: 45% 30%;
  }
  @media (max-width: 960px) {
    grid-template-columns: 48% 35%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 50% 39%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 90%;
    grid-row-gap: 30px;
    margin: 40px 0;
  }
`
const ContentLayoutGrid2 = styled.div`
  display: grid;
  grid-template-columns: 29% 30%;
  grid-column-gap: 8%;
  margin: 70px 0 40px 0;
  justify-content: center;
  @media (max-width: 1200px) {
    grid-template-columns: 36% 37%;
  }
  @media (max-width: 960px) {
    grid-template-columns: 42% 38%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 45% 39%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 80%;
    grid-row-gap: 30px;
    margin: 40px 0;
  }
`
const ContentText = styled.p`
  font-size: 18px;
  margin: 0;
  line-height: 1.4;
  padding-top: 0.6em;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const ContentImage = styled.div`
  display: grid;
  grid-template-columns: 100%;
  align-content: center;
`
const ContentTextContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  color: #33475b;
  font-family: 'Montserrat', sans-serif;
  align-content: space-evenly;
  grid-row-gap: 1em;
  @media (max-width: 575px) {
    text-align: center;
  }
`
const FAQTitle = styled.p`
  text-align: center;
  font-size: 40px;
  color: #33475b;
  margin: 80px 0 50px 0;
`
const FaqContainer = styled.div`
  display: grid;
  grid-template-columns: 700px;
  justify-content: center;
  margin-bottom: 60px;
  @media (max-width: 767px) {
    padding: 0 15px;
    grid-template-columns: 80%;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
    grid-template-columns: 95%;
  }
`

const CardPartner = props => (
  <StyledCardPartner>
    <div>{props.cardImage}</div>
    <CardPartnerTitle>{props.cardTitle}</CardPartnerTitle>
    <CardPartnerText>{props.cardText}</CardPartnerText>
    <CardPartnerButtonGrid>
      <StyledA target="_blank" rel="noopener noreferrer" href={props.cardHref}>
        <CardPartnerButton>Start Earning</CardPartnerButton>
      </StyledA>
    </CardPartnerButtonGrid>
  </StyledCardPartner>
)

export default ({ data, location }) => (
  <>
    <SEO
      title="| Join Our Affiliate Program & Earn Referral Commissions"
      description="Groupboss has got the affiliate program. It has created an opportunity for many affiliates to earn money by promoting Groupboss."
      pathname={location.pathname}
    />
    <TitleGrid>
      <PartnerH1>
      New Affiliate System of Groupboss is Coming Soon
      </PartnerH1>
      <TitleSubH3>
      Please fill up the form by clicking button below to join our affiliate program.So far we had been using our Payment processor Paddle to manage all our activities related to Affiliate.
      </TitleSubH3>
      <TitleSubH3>
      But, Paddle decided to close the affiliate feature and they retired it completely on 28th Feb 2023. Decision regarding our new affiliate system is in progress. We will let you know the further update. 
      </TitleSubH3>
      <ApplyAffiliateGrid>
        <StyledA
          target="_blank"
          rel="noopener noreferrer"
          href="https://forms.gle/Q1rQNEksmjSFeYHH9"
        >
          <ApplyAffiliateButton>
            Apply to be a Groupboss Affiliate
          </ApplyAffiliateButton>
        </StyledA>
      </ApplyAffiliateGrid>
      <p style={{ textAlign: `center` }}>
        *Clicking this button means you are agreed with our{" "}
        <StyledLink to="/terms-of-service">terms and conditions</StyledLink>
      </p>
    </TitleGrid>
    <CardContainerPartner>
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_1.childImageSharp.gatsbyImageData}
            alt="takiing notes" />
        }
        cardTitle="Write Blog Posts"
        cardText="For instance a Groupboss vs. ________ (any tool you can compare with or can 
            recommend switching from) post. Publish it, share on your social media, post on 
            Facebook groups and send it out to your email list."
        cardHref="https://a.paddle.com/join/program/107511"
      />
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_2.childImageSharp.gatsbyImageData}
            alt="typing laptop" />
        }
        cardTitle="Edit Your Existing Blog"
        cardText="Add details about Groupboss along with your affiliate link to 
                  any pre-existing pages or posts you have on how to make a quiz, 
                  calculator, recommendation, assessment, chatbot, etc."
        cardHref="https://a.paddle.com/join/program/107511"
      />
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_3.childImageSharp.gatsbyImageData}
            alt="person on meeting" />
        }
        cardTitle="Co-host a Webinar"
        cardText="Co-host a webinar with us that your audiences to your audience 
                  and we will be happy to teach free interactive content marketing 
                  to them! Sometimes we offer some exclusive discounts as well."
        cardHref="https://a.paddle.com/join/program/107511"
      />
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_4.childImageSharp.gatsbyImageData}
            alt="person taking to audience" />
        }
        cardTitle="For Influencers"
        cardText="Insert advertisements or shout-outs in your webinar, Podcast or 
                  Youtube episodes along with your affiliate link in the captions. 
                  For getting resources like banners, logos, etc please contact support."
        cardHref="https://a.paddle.com/join/program/107511"
      />
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_5.childImageSharp.gatsbyImageData}
            alt="paid advertising art work" />
        }
        cardTitle="Paid Ads"
        cardText="Put banner ads on your targeted websites that you feel those traffic will 
                  convert or run a Facebook ad campaign on your custom audience. Please do 
                  not run ads on keywords like - “groupboss”. For more details, 
                  you can check our terms and conditions."
        cardHref="https://a.paddle.com/join/program/107511"
      />
      <CardPartner
        cardImage={
          <GatsbyImage
            image={data.partnerCard_6.childImageSharp.gatsbyImageData}
            alt="greetings" />
        }
        cardTitle="Recommended Marketing Solution"
        cardText="Add Groupboss to the Resource page on your website as your recommended 
                  marketing solution. Also feel free to try anything else you can think 
                  of, Be Creative!"
        cardHref="https://a.paddle.com/join/program/107511"
      />
    </CardContainerPartner>

    <TitleGrid>
      <PartnerH2>Easy 2 Steps To Start Earning</PartnerH2>
      <TitleSubText>Get Paid 30% for Paying It Forward</TitleSubText>
    </TitleGrid>

    <ContentLayoutGrid>
      <ContentImage>
        <GatsbyImage
          image={data.partnerCard_7.childImageSharp.gatsbyImageData}
          alt="client checking revenue" />
      </ContentImage>
      <ContentTextContainer>
        <div>
          <CardPartnerTitle>
            1. Sign up and get your referral code
          </CardPartnerTitle>
          <ContentText>
            After signing up, you will get your own referral code. Copy your
            personal referral link and share it for a 30% straight commission.
          </ContentText>
        </div>
        <div>
          <CardPartnerTitle>2. Get Rewarded</CardPartnerTitle>
          <ContentText>
            Once your folks join, you earn a 30% cut for every new customer who
            discovers Groupboss from your referral link.
          </ContentText>
        </div>
      </ContentTextContainer>
    </ContentLayoutGrid>

    <TitleGrid>
      <PartnerH2>What makes Groupboss Affiliate awesome?</PartnerH2>
      <TitleSubText>
        Making our partnership official is a huge deal, so in return, we’ll help
        with everything you need. You’ll get:
      </TitleSubText>
    </TitleGrid>

    <ContentLayoutGrid2>
      <ContentTextContainer>
        <div>
          <CardPartnerTitle>
            No Max limit on referring or Cashout
          </CardPartnerTitle>
          <ContentText>
            There is no limit on the number of people you can refer to
            Groupboss. You can Cash Out as little as $100 and any amount above
            it. There is no max limit for cash out.
          </ContentText>
        </div>
        <div>
          <CardPartnerTitle>
            A Custom Dashboard to Track Progress
          </CardPartnerTitle>
          <ContentText>
            Check how your referral strategies are working with a dashboard
            tracking every click and reward. It’s one browser tab we bet you
            won’t be able to resist refreshing.
          </ContentText>
        </div>
        <div>
          <CardPartnerTitle>
            Handy Resources and Training Materials
          </CardPartnerTitle>
          <ContentText>
            Need some quality enablement assets to put your best foot forward?
            You got it! You can start referring right away with our training and
            promotional materials.
          </ContentText>
        </div>
      </ContentTextContainer>
      <ContentImage>
        <GatsbyImage
          image={data.partnerCard_8.childImageSharp.gatsbyImageData}
          alt="handshake" />
      </ContentImage>
    </ContentLayoutGrid2>

    <FAQTitle>Frequently Asked Questions</FAQTitle>
    <FaqContainer>
      <Accordion
        title="Is the Partner Program open to everyone?"
        content="Yep! Anyone who wants to join can be a Groupboss Partner."
      />
      <Accordion
        title="How will I receive my payment?"
        content="Receive your payment the way you want. 
        We use Paddle for our payments. Paddle would notify/settle 
        your payment when you make a sale."
      />
      <AccordionWithLink
        title="I have signed up for the Groupboss affiliate program. How can I get the link to promote Groupboss?"
        content="Once you have signed up for Groupboss affiliate program, you need the link to promote Groupboss. You will get the link in your Paddle account under Affiliates section."
        link={
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/help/groupboss-affiliate-program-faqs/"
            style={{ color: `#0000EE` }}
          >
            learn more about Groupboss affiliate program
          </StyledA>
        }
      />
      <Accordion
        title="Have more questions?"
        content="Please send a mail to “contact@groupboss.io” if you have more queries."
      />
      <Accordion
        title="How my referrals are being tracked?"
        content="We use cookies to track your referral traffic. The cookies last for 90 days after someone clicks on your link. You’ll get 90 days restarts if they click again."
      />

      <AccordionWithLink
        title="Groupboss is so cool. How can I be a partner of you?"
        content="Thanks a lot. We made a dedicated page for our awesome partners. Please visit -"
        link={<StyledLink to="/partner"> Become a Partner </StyledLink>}
      />
    </FaqContainer>
  </>
)
export const query = graphql`{
  partnerCard_1: file(relativePath: {eq: "partnerCard_1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_2: file(relativePath: {eq: "partnerCard_2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_3: file(relativePath: {eq: "partnerCard_3.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_4: file(relativePath: {eq: "partnerCard_4.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_5: file(relativePath: {eq: "partnerCard_5.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_6: file(relativePath: {eq: "partnerCard_6.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_7: file(relativePath: {eq: "partnerCard_7.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  partnerCard_8: file(relativePath: {eq: "partnerCard_8.png"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        height: 1800
        placeholder: NONE
        layout: FULL_WIDTH
      )
    }
  }
}
`
