import React, { useState, useRef } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Chevron from "./Chevron"
import styled from "styled-components"
import "../styles/Accordion.css"

const AccordionContainer = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: 35% 45%;
  grid-column-gap: 50px;
  justify-content: space-evenly;
  padding: 20px 15px;

  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-row-gap: 40px;
  }
`

const AccordTitleImageGrid = styled.div`
  display: grid;
  grid-template-rows: 225px 60%;
  justify-content: space-between;
  grid-row-gap: 40px;
  @media (max-width: 767px) {
    justify-content: space-evenly;
    grid-row-gap: 20px;
  }
`

const AccordTitleFlex = styled.div`
  display: grid;
  grid-template-columns: 80%;
  grid-template-rows: auto auto auto;
  grid-row-gap: 25px;
  @media (max-width: 767px) {
    grid-row-gap: 5px;
    justify-content: space-evenly;
  }
`

const AccordTitle = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color: rgba(47, 57, 78, 1);
  line-height: 1.15;
  margin: 0;
  @media (max-width: 575px){
    font-size: 18px;
  }
`

const AccordLine = styled.div`
  width: 80px;
  height: 3px;
  background-color: rgba(47, 57, 78, 1);
`

const AccordText = styled.div`
  font-size: 18px;
  color: rgba(47, 57, 78, 1);
  @media (max-width: 575px){
    font-size: 14px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-weight: bold;
`

export const Accordion = props => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    )
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
        <div className="chevron-grid">
          <Chevron className={`${setRotate}`} width={10} fill={"#33cdd1"} />
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  )
}
export const AccordionWithLink = props => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    )
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
        <div className="chevron-grid">
          <Chevron className={`${setRotate}`} width={10} fill={"#33cdd1"} />
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div className="accordion__text" />
        <span>{props.content}</span> {props.link}
      </div>
    </div>
  )
}

export const PricingAccordion = () => (
  <AccordionContainer>
    <AccordTitleImageGrid>
      <AccordTitleFlex>
        <AccordTitle>Frequently Asked Questions</AccordTitle>
        <AccordLine />
        <AccordText>Get answers to the common queries</AccordText>
      </AccordTitleFlex>

      <div>
        <StaticQuery
          query={query}
          render={data => (
            <GatsbyImage
              image={data.faq.childImageSharp.gatsbyImageData}
              style={{ zIndex: `-1` }}
            />
          )}
        />
      </div>
    </AccordTitleImageGrid>
    <div>
      <Accordion
        title="Do I need to ask for an email address in the group joining questions?"
        content="Yes, If you want to get the email of your members, then asking them directly is the easiest to do that. Facebook doesn't share its database. But, If you do not need emails but still want to use our app to track and manage your group better, you are welcome."
      />
      <Accordion title="Do you accept payment in PayPal?" content="Yes." />
      <Accordion
        title="What should I do if I want to add more groups?"
        content="It is the same process as adding the first group. You can learn more detail from our Getting Started Tutorial."
      />
      <Accordion
        title="How do I get my refund?"
        content="Within the first 14 days, if you do not like Groupboss or whatsoever, we will return your full payment you made. Reach to our chat support to get your refund"
      />
      <AccordionWithLink
        title="Groupboss is so cool. How can I be a partner of you?"
        content="Thanks a lot. We made a dedicated page for our awesome partners. Please visit -"
        link={<StyledLink to="/partner"> Become a Partner </StyledLink>}
      />
    </div>
  </AccordionContainer>
)

export const SpecialOfferAccordion = props => (
  <AccordionContainer>
    <AccordTitleImageGrid>
      <AccordTitleFlex>
        <AccordTitle>Frequently Asked Questions</AccordTitle>
        <AccordLine />
        <AccordText>Get answers to the common queries</AccordText>
      </AccordTitleFlex>

      <div>{props.faqImage}</div>
    </AccordTitleImageGrid>
    <div>
      <Accordion
        title="Do I need to ask for an email address in the group joining questions?"
        content="Yes, If you want to get the email of your members, then asking them directly is the easiest to do that. Facebook doesn't share its database. But, If you do not need emails but still want to use our app to track and manage your group better, you are welcome."
      />
      <Accordion title="Do you accept payment in PayPal?" content="Yes." />
      <Accordion
        title="What should I do if I want to add more groups?"
        content="It si the same process as you did for the first group."
      />
      <Accordion
        title="How do I get my refund?"
        content="Within the first 14 days, if you do not like Groupboss or whatsoever, we will return your full payment you made."
      />
      <AccordionWithLink
        title="Groupboss is so cool. How can I be a partner of you?"
        content="Thanks a lot. We made a dedicated page for our awesome partners. Please visit -"
        link={<StyledLink to="/partner"> Become a Partner </StyledLink>}
      />
    </div>
  </AccordionContainer>
)

export const query = graphql`
  {
    faq: file(relativePath: { eq: "faq.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
